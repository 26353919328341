<template>
    <div>
        <label for="number">Add Container:</label>
        <input
            id="number"
            v-model="number"
            class="form-control"
            type="text"
            :class="{
                'is-invalid': hasError('number')
            }"
            placeholder="XXXU0000000"
        />
        <div class="invalid-feedback">
            {{ getError('number') }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        validation: {
            type: Object,
            required: true
        },
        serverErrors: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            number: ''
        };
    },

    watch: {
        value() {
            this.number = this.value;
        },

        number() {
            this.$emit('input', this.number);
        }
    },

    methods: {
        hasError(key) {
            return (
                this.validation[key].$error ||
                this.serverErrors.find(error => error.param === key)
            );
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.validation,
                this.serverErrors,
                key
            );
        }
    }
};
</script>
