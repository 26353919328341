<template>
    <b-modal
        :id="id"
        title="Import Data"
        centered
        no-close-on-backdrop
        @hidden="close"
    >
        <b-form-group label="CSV file">
            <b-form-file
                id="file-small"
                v-model="file"
                size="sm"
                accept=".csv"
                @change="onChange"
            />
        </b-form-group>

        <div class="import-csv-modal">
            <div class="errors-box">
                <div
                    v-for="(error, index) in errors"
                    :key="index"
                    class="single-error"
                >
                    {{ error }}
                </div>
            </div>
            <div v-if="response" class="response-box">
                <div class="success">
                    <span>Success: {{ response.success }} </span>
                </div>
                <div class="failed">
                    <span> Failed: {{ response.fail }} </span>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="close"
                >
                    Close
                </button>
                <a href="/files/containers.csv">
                    <button
                        type="button"
                        class="btn btn-info waves-effect waves-light mr-2"
                    >
                        Download sample
                    </button>
                </a>
                <button
                    type="submit"
                    class="btn btn-success btn-static waves-effect waves-light"
                    :disabled="!file || inProcess"
                    @click="importFile"
                >
                    <loader v-if="inProcess" />

                    <span v-else>
                        Agree
                    </span>
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { startCase } from 'lodash';
import * as Sentry from '@sentry/vue';
import { StatusCodes as HTTP } from 'http-status-codes';

import Loader from '@/components/elements/Loader';

export default {
    components: {
        Loader
    },

    props: {
        path: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: 'import-csv'
        }
    },

    data() {
        return {
            file: null,
            serverErrors: [],
            response: null,
            inProcess: false
        };
    },

    computed: {
        errors() {
            if (!this.serverErrors.length) {
                return [];
            }

            const errors = this.serverErrors.map(({ message, param }) => {
                const [, index, , property] = param.split(/\[|\]|\./);

                return `${startCase(property)} in row ${index}: ${message}`;
            });

            return errors;
        }
    },

    methods: {
        ...mapActions({
            importCsv: 'containers/importCsv'
        }),

        async importFile() {
            this.inProcess = true;
            this.serverErrors = [];
            this.response = null;

            try {
                this.response = await this.importCsv(this.file);

                this.$toaster('Imported successfully');

                this.$emit('imported');

                this.close();
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        return;
                    }
                }

                console.error(error);

                Sentry.captureException(error);

                this.$toasterError();
            } finally {
                this.inProcess = false;
            }
        },

        close() {
            this.file = null;
            this.serverErrors = [];
            this.response = null;
            this.$bvModal.hide(this.id);
        },

        onChange() {
            this.serverErrors = [];
            this.response = null;
        }
    }
};
</script>
