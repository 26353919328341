<template>
    <div class="monitor">
        <page-header :title="title" :items="items" />

        <search-form @search="handleSearch" />

        <track-component @added="handleEvent" @imported="handleEvent" />

        <board-view :key="key" :search="search" />
    </div>
</template>

<script>
import BoardView from '@/components/containers/BoardView';
import TrackComponent from '@/components/containers/Track';
import SearchForm from '@/components/containers/SearchForm';

export default {
    page: {
        title: 'Containers'
    },

    components: {
        BoardView,
        SearchForm,
        TrackComponent
    },

    data() {
        return {
            title: 'Containers',
            items: [
                {
                    text: 'Container Tracking',
                    to: '/'
                },
                {
                    text: 'Containers',
                    active: true
                }
            ],
            search: '',
            key: 0
        };
    },

    methods: {
        handleSearch(search) {
            this.search = search;
        },

        handleEvent() {
            this.key++;
        }
    }
};
</script>
