<template>
    <div>
        <div class="row monitor-wrapper">
            <monitor-column
                title="On the way"
                :search="search"
                :status="ON_THE_WAY"
                background="on-the-way"
            />

            <monitor-column
                title="Arrived to destination"
                :search="search"
                :status="ARRIVED"
                background="arrived"
            />

            <monitor-column
                title="Done"
                :search="search"
                :status="DONE"
                background="done"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MonitorColumn from '@/components/containers/MonitorColumn';

export default {
    components: {
        MonitorColumn
    },

    props: {
        search: {
            type: String,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            ON_THE_WAY: 'containers/ON_THE_WAY',
            ARRIVED: 'containers/ARRIVED',
            DONE: 'containers/DONE'
        })
    }
};
</script>
