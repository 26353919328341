<template>
    <div class="monitor-column">
        <div class="column-info">
            <div class="text-uppercase text-center">
                {{ title }}
            </div>
        </div>
        <div class="column-list">
            <div
                v-for="(container, index) in containers"
                :key="index"
                class="column-item"
            >
                <div class="item-information">
                    <div class="info-label cursor-pointer">
                        Container ID:
                        <router-link :to="`/containers/${container.id}`">
                            {{ container.number }}
                        </router-link>
                    </div>

                    <div class="info-label">
                        <i class="fas fa-map-marker-alt" />
                        <span>
                            {{ container.supplierStatus
                            }}{{ container.finalPod ? ', ' : '' }}
                        </span>
                        <span v-if="container.finalPod">
                            <i class="fas fa-map-marker-alt" />
                            {{ container.finalPod }}
                        </span>
                    </div>

                    <div class="info-label">
                        Final Pod ETA: {{ container.finalPodEta | dateOnly }}
                    </div>

                    <div class="info-label">
                        Has arrived: {{ container.hasArrived ? 'Yes' : 'No' }}
                    </div>

                    <div class="info-label">
                        Departed from port:
                        {{ container.departFromPort ? 'Yes' : 'No' }}
                    </div>

                    <div class="info-label d-flex">
                        Report Image link:
                        <div class="report-icon-wrapper">
                            <a :href="container.imagePath">
                                <i class="fas fa-image icon" />
                            </a>
                        </div>
                    </div>

                    <div class="info-label">
                        Transit time in days: {{ container.transitTime }}
                    </div>

                    <div class="info-label text-center mt-2">
                        {{ container.reportedAt | formatDate }}
                    </div>

                    <div
                        class="info-label text-center bordered"
                        :class="background"
                    >
                        {{ title }}
                    </div>
                </div>
            </div>
            <div
                v-if="isMoreAvailable"
                class="get-more card"
                @click="fetchData(true)"
            >
                <div class="card-body">
                    Get more <i class="fas fa-arrow-down" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
    props: {
        title: {
            type: String,
            required: true
        },

        search: {
            type: String,
            required: true
        },

        status: {
            type: String,
            required: true
        },

        background: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            containers: [],
            currentPage: 1,
            perPage: 15,
            total: 0
        };
    },

    computed: {
        isMoreAvailable() {
            return this.total > this.containers.length;
        }
    },

    watch: {
        search() {
            this.fetchData();
        }
    },

    created() {
        this.fetchData();
    },

    methods: {
        ...mapActions({
            getItems: 'containers/index'
        }),

        async fetchData(getMore = false) {
            try {
                this.currentPage = getMore ? this.currentPage + 1 : 1;

                const params = {
                    currentPage: this.currentPage,
                    perPage: this.perPage,
                    search: this.search,
                    status: this.status
                };

                const { rows, count } = await this.getItems(params);

                this.total = count;

                this.containers = getMore
                    ? [...this.containers, ...rows]
                    : rows;
            } catch (err) {
                console.error(err);

                Sentry.captureException(err);

                this.$toasterError();
            }
        }
    }
};
</script>
