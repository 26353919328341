<template>
    <div class="row">
        <div class="col-12 col-xl-6 form-group align-items-end">
            <input
                v-model="search"
                type="text"
                class="form-control"
                dir="auto"
                placeholder="Searching for container..."
                @input="onSearchInput"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: ''
        };
    },

    methods: {
        onSearchInput() {
            this.$emit('search', this.search);
        }
    }
};
</script>
