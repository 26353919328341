<template>
    <div class="row">
        <div class="col-12 col-xl-6 form-group align-items-end">
            <track-form
                v-model="number"
                :validation="$v"
                :server-errors="serverErrors"
            />

            <button
                class="btn btn-success btn-static waves-effect waves-light my-2 mr-2"
                :disabled="loading"
                @click="submitHandler"
            >
                <loader v-if="loading" />

                <span v-else>
                    <i class="fas fa-plus-circle" />
                    Add
                </span>
            </button>

            <button
                v-b-modal.import-csv
                class="btn btn-success btn-static waves-effect waves-light my-2"
            >
                <i class="fas fa-upload" />
                Import
            </button>

            <import-csv-modal
                v-if="importPath"
                :path="importPath"
                @imported="handleImport"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { required } from 'vuelidate/lib/validators';
import { StatusCodes as HTTP } from 'http-status-codes';

import Loader from '@/components/elements/Loader';
import TrackForm from '@/components/containers/TrackForm';
import ImportCsvModal from '@/components/elements/ImportCsvModal';

export default {
    validations: {
        number: {
            required,
            isFormatValid: value => /^[A-Z]{3}U[0-9]{7}$/.test(value)
        }
    },

    components: {
        Loader,
        TrackForm,
        ImportCsvModal
    },

    data() {
        return {
            number: '',
            serverErrors: [],
            importPath: 'Container',
            loading: false
        };
    },

    methods: {
        ...mapActions({
            trackContainer: 'containers/track'
        }),

        async submitHandler() {
            this.serverErrors = [];

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                this.loading = true;

                await this.trackContainer(this.number);

                this.$toaster('Added container to track.');

                this.number = '';
                this.$v.$reset();

                this.$emit('added');
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;
                        this.$toasterError('Recheck your form.');

                        return;
                    }

                    if (status === HTTP.BAD_REQUEST) {
                        this.$toasterError(data);

                        return;
                    }
                }

                console.error(error);

                Sentry.captureException(error);

                this.$toasterError();
            } finally {
                this.loading = false;
            }
        },

        handleImport() {
            this.$emit('imported');
        }
    }
};
</script>
